*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

* {
  box-sizing: border-box;
}

body {
  overscroll-behavior: none;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  margin: 0;
  font-family: filson-pro, sans-serif;
  display: flex;
}

@media (min-width: 1024px) {
  body {
    --tw-bg-opacity: 1;
    background-color: rgb(255 248 231 / var(--tw-bg-opacity));
  }
}

body {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

#app {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

h1 {
  --tw-text-opacity: 1;
  color: rgb(137 66 145 / var(--tw-text-opacity));
  font-size: 3rem;
  font-weight: 700;
}

h2 {
  --tw-text-opacity: 1;
  color: rgb(137 66 145 / var(--tw-text-opacity));
  font-size: 1.9rem;
  font-weight: 700;
}

h3 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
  font-size: 1.4rem;
  font-weight: 700;
}

video {
  transform: rotateY(180deg);
}

.center-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.upload-wrapper {
  position: relative;
}

.upload-wrapper input[type="file"] {
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-bottom-1 {
  bottom: -.25rem;
}

.-left-1 {
  left: -.25rem;
}

.-left-1\.5 {
  left: -.375rem;
}

.-left-3 {
  left: -.75rem;
}

.-right-1 {
  right: -.25rem;
}

.-top-1 {
  top: -.25rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-\[60px\] {
  bottom: 60px;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-2 {
  left: .5rem;
}

.left-3 {
  left: .75rem;
}

.left-4 {
  left: 1rem;
}

.left-5 {
  left: 1.25rem;
}

.left-6 {
  left: 1.5rem;
}

.left-\[185px\] {
  left: 185px;
}

.left-\[50px\] {
  left: 50px;
}

.right-0 {
  right: 0;
}

.right-\[50px\] {
  right: 50px;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-\[1px\] {
  top: 1px;
}

.top-\[200px\] {
  top: 200px;
}

.top-\[2px\] {
  top: 2px;
}

.top-\[30px\] {
  top: 30px;
}

.top-\[55px\] {
  top: 55px;
}

.\!z-50 {
  z-index: 50 !important;
}

.-z-10 {
  z-index: -10;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.-mx-1\.5 {
  margin-left: -.375rem;
  margin-right: -.375rem;
}

.-my-1 {
  margin-top: -.25rem;
  margin-bottom: -.25rem;
}

.-my-1\.5 {
  margin-top: -.375rem;
  margin-bottom: -.375rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[2px\] {
  margin-top: 2px;
}

.mt-\[3px\] {
  margin-top: 3px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-2\/5 {
  height: 40%;
}

.h-20 {
  height: 5rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-96 {
  height: 24rem;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-min {
  height: min-content;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.max-h-\[450px\] {
  max-height: 450px;
}

.max-h-\[70px\] {
  max-height: 70px;
}

.max-h-\[90vh\] {
  max-height: 90vh;
}

.max-h-screen {
  max-height: 100vh;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\/4 {
  width: 50%;
}

.w-20 {
  width: 5rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-3\/5 {
  width: 60%;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-\[325px\] {
  width: 325px;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-\[126px\] {
  max-width: 126px;
}

.max-w-\[180px\] {
  max-width: 180px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[450px\] {
  max-width: 450px;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-none {
  transform: none;
}

@keyframes melt {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(.8);
  }

  20% {
    transform: scale(1.2);
  }

  30% {
    transform: scale(.9);
  }

  40% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.animate-melt {
  animation: 3s ease-in-out 1s infinite melt;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-grab {
  cursor: grab;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}

.resize {
  resize: both;
}

.snap-x {
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
}

.snap-mandatory {
  --tw-scroll-snap-strictness: mandatory;
}

.snap-center {
  scroll-snap-align: center;
}

.list-none {
  list-style-type: none;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1rem * var(--tw-space-x-reverse));
  margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
}

.-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
}

.-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-divide-opacity));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-0 {
  border-left-width: 0;
}

.border-r-0 {
  border-right-width: 0;
}

.border-t {
  border-top-width: 1px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-t-\[1px\] {
  border-top-width: 1px;
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
}

.border-cyan-300 {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity));
}

.border-cyan-500 {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity));
}

.border-cyan-600 {
  --tw-border-opacity: 1;
  border-color: rgb(8 145 178 / var(--tw-border-opacity));
}

.border-cyan-700 {
  --tw-border-opacity: 1;
  border-color: rgb(14 116 144 / var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgb(134 239 172 / var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity));
}

.border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgb(165 180 252 / var(--tw-border-opacity));
}

.border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgb(129 140 248 / var(--tw-border-opacity));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.border-lime-300 {
  --tw-border-opacity: 1;
  border-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.border-lime-400 {
  --tw-border-opacity: 1;
  border-color: rgb(163 230 53 / var(--tw-border-opacity));
}

.border-lime-500 {
  --tw-border-opacity: 1;
  border-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgb(249 168 212 / var(--tw-border-opacity));
}

.border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgb(236 72 153 / var(--tw-border-opacity));
}

.border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgb(219 39 119 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(112 42 121 / var(--tw-border-opacity));
}

.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgb(88 28 135 / var(--tw-border-opacity));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgb(127 29 29 / var(--tw-border-opacity));
}

.border-teal-300 {
  --tw-border-opacity: 1;
  border-color: rgb(94 234 212 / var(--tw-border-opacity));
}

.border-teal-500 {
  --tw-border-opacity: 1;
  border-color: rgb(20 184 166 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 224 71 / var(--tw-border-opacity));
}

.border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgb(250 204 21 / var(--tw-border-opacity));
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity));
}

.border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgb(202 138 4 / var(--tw-border-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(33 37 41 / var(--tw-bg-opacity));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}

.bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}

.bg-cyan-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 254 255 / var(--tw-bg-opacity));
}

.bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}

.bg-cyan-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}

.bg-cyan-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-grayButton {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.bg-lime-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}

.bg-lime-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 230 53 / var(--tw-bg-opacity));
}

.bg-lime-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}

.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(112 42 121 / var(--tw-bg-opacity));
}

.bg-primaryAlt {
  --tw-bg-opacity: 1;
  background-color: rgb(252 217 0 / var(--tw-bg-opacity));
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}

.bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}

.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 205 / var(--tw-bg-opacity));
}

.bg-teal-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(204 251 241 / var(--tw-bg-opacity));
}

.bg-teal-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/30 {
  background-color: #ffffff4d;
}

.bg-white\/50 {
  background-color: #ffffff80;
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 204 21 / var(--tw-bg-opacity));
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 252 232 / var(--tw-bg-opacity));
}

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(202 138 4 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-confetti {
  background-image: url("background.aa562618.png");
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-cyan-400 {
  --tw-gradient-from: #22d3ee var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500 {
  --tw-gradient-from: #06b6d4 var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green-400 {
  --tw-gradient-from: #4ade80 var(--tw-gradient-from-position);
  --tw-gradient-to: #4ade8000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200 {
  --tw-gradient-from: #d9f99d var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400 {
  --tw-gradient-from: #f472b6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500 {
  --tw-gradient-from: #ec4899 var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500 {
  --tw-gradient-from: #a855f7 var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600 {
  --tw-gradient-from: #9333ea var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red-200 {
  --tw-gradient-from: #fecaca var(--tw-gradient-from-position);
  --tw-gradient-to: #fecaca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red-400 {
  --tw-gradient-from: #f87171 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8717100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200 {
  --tw-gradient-from: #99f6e4 var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400 {
  --tw-gradient-from: #2dd4bf var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-cyan-500 {
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #06b6d4 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-cyan-600 {
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0891b2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-green-500 {
  --tw-gradient-to: #22c55e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #22c55e var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e635 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333ea var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-red-300 {
  --tw-gradient-to: #fca5a500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-red-500 {
  --tw-gradient-to: #ef444400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-teal-500 {
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #14b8a6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.to-cyan-500 {
  --tw-gradient-to: #06b6d4 var(--tw-gradient-to-position);
}

.to-cyan-600 {
  --tw-gradient-to: #0891b2 var(--tw-gradient-to-position);
}

.to-cyan-700 {
  --tw-gradient-to: #0e7490 var(--tw-gradient-to-position);
}

.to-green-600 {
  --tw-gradient-to: #16a34a var(--tw-gradient-to-position);
}

.to-lime-200 {
  --tw-gradient-to: #d9f99d var(--tw-gradient-to-position);
}

.to-lime-500 {
  --tw-gradient-to: #84cc16 var(--tw-gradient-to-position);
}

.to-orange-400 {
  --tw-gradient-to: #fb923c var(--tw-gradient-to-position);
}

.to-pink-500 {
  --tw-gradient-to: #ec4899 var(--tw-gradient-to-position);
}

.to-pink-600 {
  --tw-gradient-to: #db2777 var(--tw-gradient-to-position);
}

.to-purple-700 {
  --tw-gradient-to: #7e22ce var(--tw-gradient-to-position);
}

.to-red-600 {
  --tw-gradient-to: #dc2626 var(--tw-gradient-to-position);
}

.to-teal-600 {
  --tw-gradient-to: #0d9488 var(--tw-gradient-to-position);
}

.to-yellow-200 {
  --tw-gradient-to: #fef08a var(--tw-gradient-to-position);
}

.bg-contain {
  background-size: contain;
}

.bg-left-top {
  background-position: 0 0;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-current {
  fill: currentColor;
}

.fill-cyan-600 {
  fill: #0891b2;
}

.fill-gray-600 {
  fill: #4b5563;
}

.fill-green-500 {
  fill: #22c55e;
}

.fill-pink-600 {
  fill: #db2777;
}

.fill-purple-600 {
  fill: #9333ea;
}

.fill-red-600 {
  fill: #dc2626;
}

.fill-yellow-400 {
  fill: #facc15;
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-2\.5 {
  padding-left: .625rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.9rem;
}

.text-3xl {
  font-size: 2.4rem;
}

.text-3xs {
  font-size: .65rem;
}

.text-4xl {
  font-size: 3rem;
}

.text-7xl {
  font-size: 6rem;
}

.text-base {
  font-size: 1.25rem;
}

.text-lg {
  font-size: 1.4rem;
}

.text-sm {
  font-size: 1rem;
}

.text-xl {
  font-size: 1.5rem;
}

.text-xs {
  font-size: .9rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(33 37 41 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-cyan-300 {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity));
}

.text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity));
}

.text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}

.text-cyan-700 {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity));
}

.text-cyan-800 {
  --tw-text-opacity: 1;
  color: rgb(21 94 117 / var(--tw-text-opacity));
}

.text-cyan-900 {
  --tw-text-opacity: 1;
  color: rgb(22 78 99 / var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}

.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity));
}

.text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}

.text-lime-500 {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity));
}

.text-lime-700 {
  --tw-text-opacity: 1;
  color: rgb(77 124 15 / var(--tw-text-opacity));
}

.text-lime-800 {
  --tw-text-opacity: 1;
  color: rgb(63 98 18 / var(--tw-text-opacity));
}

.text-lime-900 {
  --tw-text-opacity: 1;
  color: rgb(54 83 20 / var(--tw-text-opacity));
}

.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity));
}

.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity));
}

.text-pink-800 {
  --tw-text-opacity: 1;
  color: rgb(157 23 77 / var(--tw-text-opacity));
}

.text-pink-900 {
  --tw-text-opacity: 1;
  color: rgb(131 24 67 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(112 42 121 / var(--tw-text-opacity));
}

.text-primary-400 {
  --tw-text-opacity: 1;
  color: rgb(140 53 151 / var(--tw-text-opacity));
}

.text-primary-600 {
  --tw-text-opacity: 1;
  color: rgb(73 28 79 / var(--tw-text-opacity));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity));
}

.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity));
}

.text-teal-700 {
  --tw-text-opacity: 1;
  color: rgb(15 118 110 / var(--tw-text-opacity));
}

.text-teal-800 {
  --tw-text-opacity: 1;
  color: rgb(17 94 89 / var(--tw-text-opacity));
}

.text-teal-900 {
  --tw-text-opacity: 1;
  color: rgb(19 78 74 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgb(161 98 7 / var(--tw-text-opacity));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
}

.text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgb(113 63 18 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.placeholder-cyan-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(14 116 144 / var(--tw-placeholder-opacity));
}

.placeholder-green-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(21 128 61 / var(--tw-placeholder-opacity));
}

.placeholder-red-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(185 28 28 / var(--tw-placeholder-opacity));
}

.placeholder-yellow-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(161 98 7 / var(--tw-placeholder-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-8 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-cyan-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity));
}

.ring-cyan-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(14 116 144 / var(--tw-ring-opacity));
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

.ring-gray-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity));
}

.ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity));
}

.ring-green-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity));
}

.ring-pink-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity));
}

.ring-purple-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity));
}

.ring-red-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.ring-yellow-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 224 71 / var(--tw-ring-opacity));
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-1000 {
  transition-duration: 1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-700 {
  transition-duration: .7s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.after\:translate-x-full:after {
  content: var(--tw-content);
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:border-white:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.first\:ml-0:first-child {
  margin-left: 0;
}

.first\:mt-0:first-child {
  margin-top: 0;
}

.first\:rounded-t-lg:first-child {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.first\:border-t-0:first-child {
  border-top-width: 0;
}

.first\:pt-0:first-child {
  padding-top: 0;
}

.last\:mr-0:last-child {
  margin-right: 0;
}

.last\:rounded-b-lg:last-child {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.odd\:bg-white:nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.even\:bg-gray-50:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:cursor-not-allowed:hover {
  cursor: not-allowed;
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.hover\:bg-cyan-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}

.hover\:bg-cyan-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.hover\:bg-green-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity));
}

.hover\:bg-lime-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}

.hover\:bg-pink-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}

.hover\:bg-purple-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}

.hover\:bg-red-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}

.hover\:bg-teal-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity));
}

.hover\:bg-gradient-to-br:hover {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.hover\:text-cyan-700:hover {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:border-cyan-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity));
}

.focus\:border-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.focus\:border-yellow-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity));
}

.focus\:bg-gray-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.focus\:text-cyan-700:focus {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
}

.focus\:ring-cyan-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity));
}

.focus\:ring-cyan-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity));
}

.focus\:ring-cyan-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity));
}

.focus\:ring-cyan-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(14 116 144 / var(--tw-ring-opacity));
}

.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}

.focus\:ring-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

.focus\:ring-gray-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity));
}

.focus\:ring-green-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(187 247 208 / var(--tw-ring-opacity));
}

.focus\:ring-green-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(134 239 172 / var(--tw-ring-opacity));
}

.focus\:ring-green-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(74 222 128 / var(--tw-ring-opacity));
}

.focus\:ring-green-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity));
}

.focus\:ring-indigo-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(165 180 252 / var(--tw-ring-opacity));
}

.focus\:ring-indigo-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(129 140 248 / var(--tw-ring-opacity));
}

.focus\:ring-lime-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(217 249 157 / var(--tw-ring-opacity));
}

.focus\:ring-lime-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(190 242 100 / var(--tw-ring-opacity));
}

.focus\:ring-lime-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(163 230 53 / var(--tw-ring-opacity));
}

.focus\:ring-pink-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(251 207 232 / var(--tw-ring-opacity));
}

.focus\:ring-pink-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(249 168 212 / var(--tw-ring-opacity));
}

.focus\:ring-pink-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(244 114 182 / var(--tw-ring-opacity));
}

.focus\:ring-primary-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(168 63 182 / var(--tw-ring-opacity));
}

.focus\:ring-purple-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(233 213 255 / var(--tw-ring-opacity));
}

.focus\:ring-purple-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(216 180 254 / var(--tw-ring-opacity));
}

.focus\:ring-purple-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(192 132 252 / var(--tw-ring-opacity));
}

.focus\:ring-red-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(254 226 226 / var(--tw-ring-opacity));
}

.focus\:ring-red-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity));
}

.focus\:ring-red-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity));
}

.focus\:ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

.focus\:ring-teal-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(94 234 212 / var(--tw-ring-opacity));
}

.focus\:ring-teal-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(45 212 191 / var(--tw-ring-opacity));
}

.focus\:ring-yellow-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 224 71 / var(--tw-ring-opacity));
}

.focus\:ring-yellow-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(250 204 21 / var(--tw-ring-opacity));
}

.focus\:ring-yellow-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(234 179 8 / var(--tw-ring-opacity));
}

.enabled\:hover\:bg-cyan-100:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-cyan-800:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(21 94 117 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-gray-100:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-gray-900:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-green-100:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-green-800:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-indigo-100:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-lime-100:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-pink-100:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-primary-600:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(73 28 79 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-purple-800:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-red-100:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-red-800:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-teal-100:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(204 251 241 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-yellow-100:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-yellow-300:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(253 224 71 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-yellow-500:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-gradient-to-bl:hover:enabled {
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
}

.enabled\:hover\:bg-gradient-to-br:hover:enabled {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.enabled\:hover\:bg-gradient-to-l:hover:enabled {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}

.enabled\:hover\:from-teal-200:hover:enabled {
  --tw-gradient-from: #99f6e4 var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.enabled\:hover\:to-lime-200:hover:enabled {
  --tw-gradient-to: #d9f99d var(--tw-gradient-to-position);
}

.enabled\:hover\:text-cyan-700:hover:enabled {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity));
}

.enabled\:hover\:text-gray-700:hover:enabled {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.enabled\:hover\:text-gray-900:hover:enabled {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:text-gray-400:disabled {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.group:first-child .group-first\:hidden {
  display: none;
}

.group\/body:first-child .group\/row:first-child .group-first\/body\:group-first\/row\:first\:rounded-tl-lg:first-child, .group\/head:first-child .group-first\/head\:first\:rounded-tl-lg:first-child {
  border-top-left-radius: .5rem;
}

.group\/body:first-child .group\/row:first-child .group-first\/body\:group-first\/row\:last\:rounded-tr-lg:last-child, .group\/head:first-child .group-first\/head\:last\:rounded-tr-lg:last-child {
  border-top-right-radius: .5rem;
}

.group\/body:last-child .group\/row:last-child .group-last\/body\:group-last\/row\:first\:rounded-bl-lg:first-child {
  border-bottom-left-radius: .5rem;
}

.group\/body:last-child .group\/row:last-child .group-last\/body\:group-last\/row\:last\:rounded-br-lg:last-child {
  border-bottom-right-radius: .5rem;
}

.group:hover .group-hover\:bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-lime-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-white\/50 {
  background-color: #ffffff80;
}

.group:hover .group-hover\:bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.group:focus .group-focus\:outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.group:focus .group-focus\:ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.group:focus .group-focus\:ring-cyan-500\/25 {
  --tw-ring-color: #06b6d440;
}

.group:focus .group-focus\:ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.group:enabled:hover .group-enabled\:group-hover\:bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.group:enabled:hover .group-enabled\:group-hover\:text-inherit {
  color: inherit;
}

:is(.dark .dark\:divide-gray-700) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-divide-opacity));
}

:is(.dark .dark\:border-none) {
  border-style: none;
}

:is(.dark .dark\:border-blue-500) {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-cyan-400) {
  --tw-border-opacity: 1;
  border-color: rgb(34 211 238 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-cyan-500) {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-cyan-600) {
  --tw-border-opacity: 1;
  border-color: rgb(8 145 178 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-600) {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-700) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-800) {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-900) {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-green-400) {
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-green-600) {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-indigo-600) {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-lime-600) {
  --tw-border-opacity: 1;
  border-color: rgb(101 163 13 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-pink-600) {
  --tw-border-opacity: 1;
  border-color: rgb(219 39 119 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-red-400) {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-red-600) {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-teal-600) {
  --tw-border-opacity: 1;
  border-color: rgb(13 148 136 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-transparent) {
  border-color: #0000;
}

:is(.dark .dark\:border-white) {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-yellow-400) {
  --tw-border-opacity: 1;
  border-color: rgb(250 204 21 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-yellow-600) {
  --tw-border-opacity: 1;
  border-color: rgb(202 138 4 / var(--tw-border-opacity));
}

:is(.dark .dark\:bg-black) {
  --tw-bg-opacity: 1;
  background-color: rgb(33 37 41 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-blue-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-cyan-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-cyan-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-cyan-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-cyan-900) {
  --tw-bg-opacity: 1;
  background-color: rgb(22 78 99 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-300) {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-400) {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-800\/30) {
  background-color: #1f29374d;
}

:is(.dark .dark\:bg-gray-800\/50) {
  background-color: #1f293780;
}

:is(.dark .dark\:bg-gray-900) {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-green-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-green-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-green-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-green-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-green-900) {
  --tw-bg-opacity: 1;
  background-color: rgb(20 83 45 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-indigo-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-indigo-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-lime-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-pink-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-primary-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(73 28 79 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-purple-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-purple-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-purple-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-purple-900) {
  --tw-bg-opacity: 1;
  background-color: rgb(88 28 135 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-red-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-red-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-red-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-red-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-red-900) {
  --tw-bg-opacity: 1;
  background-color: rgb(127 29 29 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-teal-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-transparent) {
  background-color: #0000;
}

:is(.dark .dark\:bg-yellow-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-yellow-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-yellow-900) {
  --tw-bg-opacity: 1;
  background-color: rgb(113 63 18 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-opacity-80) {
  --tw-bg-opacity: .8;
}

:is(.dark .dark\:fill-gray-300) {
  fill: #d1d5db;
}

:is(.dark .dark\:text-blue-500) {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-cyan-100) {
  --tw-text-opacity: 1;
  color: rgb(207 250 254 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-cyan-300) {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-cyan-500) {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-cyan-600) {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-cyan-800) {
  --tw-text-opacity: 1;
  color: rgb(21 94 117 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-cyan-900) {
  --tw-text-opacity: 1;
  color: rgb(22 78 99 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-100) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-200) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-300) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-400) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-500) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-600) {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-800) {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-900) {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-green-500) {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-green-600) {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-green-800) {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-green-900) {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-indigo-600) {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-indigo-800) {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-indigo-900) {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-lime-600) {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-lime-800) {
  --tw-text-opacity: 1;
  color: rgb(63 98 18 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-lime-900) {
  --tw-text-opacity: 1;
  color: rgb(54 83 20 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-pink-600) {
  --tw-text-opacity: 1;
  color: rgb(219 39 119 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-pink-800) {
  --tw-text-opacity: 1;
  color: rgb(157 23 77 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-pink-900) {
  --tw-text-opacity: 1;
  color: rgb(131 24 67 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-primary-500) {
  --tw-text-opacity: 1;
  color: rgb(112 42 121 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-primary-600) {
  --tw-text-opacity: 1;
  color: rgb(73 28 79 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-purple-600) {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-purple-800) {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-purple-900) {
  --tw-text-opacity: 1;
  color: rgb(88 28 135 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-red-500) {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-red-600) {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-red-800) {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-red-900) {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-teal-600) {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-teal-800) {
  --tw-text-opacity: 1;
  color: rgb(17 94 89 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-teal-900) {
  --tw-text-opacity: 1;
  color: rgb(19 78 74 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-yellow-600) {
  --tw-text-opacity: 1;
  color: rgb(202 138 4 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-yellow-800) {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-yellow-900) {
  --tw-text-opacity: 1;
  color: rgb(113 63 18 / var(--tw-text-opacity));
}

:is(.dark .dark\:placeholder-gray-400)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity));
}

:is(.dark .dark\:ring-cyan-800) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(21 94 117 / var(--tw-ring-opacity));
}

:is(.dark .dark\:ring-gray-400) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity));
}

:is(.dark .dark\:ring-gray-500) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity));
}

:is(.dark .dark\:ring-gray-800) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity));
}

:is(.dark .dark\:ring-gray-900) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity));
}

:is(.dark .dark\:ring-green-500) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity));
}

:is(.dark .dark\:ring-pink-500) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity));
}

:is(.dark .dark\:ring-purple-600) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 51 234 / var(--tw-ring-opacity));
}

:is(.dark .dark\:ring-red-700) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity));
}

:is(.dark .dark\:ring-yellow-500) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(234 179 8 / var(--tw-ring-opacity));
}

:is(.dark .dark\:ring-offset-gray-800) {
  --tw-ring-offset-color: #1f2937;
}

:is(.dark .odd\:dark\:bg-gray-800):nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

:is(.dark .even\:dark\:bg-gray-700):nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-blue-700:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-cyan-300:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-gray-300:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-gray-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-gray-700:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-gray-800:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-green-300:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-indigo-300:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-lime-300:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-pink-300:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(249 168 212 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-purple-300:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-red-300:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-teal-300:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(94 234 212 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-yellow-300:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(253 224 71 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:text-blue-500:hover) {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:text-gray-300:hover) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:text-white:hover) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:focus\:border-cyan-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity));
}

:is(.dark .dark\:focus\:border-green-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

:is(.dark .dark\:focus\:border-red-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

:is(.dark .dark\:focus\:border-yellow-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity));
}

:is(.dark .dark\:focus\:bg-cyan-600:focus) {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}

:is(.dark .dark\:focus\:bg-gray-600:focus) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

:is(.dark .dark\:focus\:text-white:focus) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:focus\:ring-cyan-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-cyan-600:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(8 145 178 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-cyan-700:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(14 116 144 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-cyan-800:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(21 94 117 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-gray-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-gray-600:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-gray-700:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-gray-800:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-green-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-green-700:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(21 128 61 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-green-800:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(22 101 52 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-indigo-700:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(67 56 202 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-lime-700:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(77 124 15 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-lime-800:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(63 98 18 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-pink-700:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(190 24 93 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-pink-800:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(157 23 77 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-primary-900:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-purple-800:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(107 33 168 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-purple-900:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(88 28 135 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-red-400:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-red-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-red-700:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-red-800:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(153 27 27 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-red-900:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(127 29 29 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-teal-700:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(15 118 110 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-teal-800:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(17 94 89 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-yellow-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(234 179 8 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-yellow-700:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(161 98 7 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-yellow-900:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(113 63 18 / var(--tw-ring-opacity));
}

:is(.dark .dark\:enabled\:hover\:border-cyan-700:hover:enabled) {
  --tw-border-opacity: 1;
  border-color: rgb(14 116 144 / var(--tw-border-opacity));
}

:is(.dark .dark\:enabled\:hover\:border-gray-700:hover:enabled) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

:is(.dark .dark\:enabled\:hover\:border-green-700:hover:enabled) {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity));
}

:is(.dark .dark\:enabled\:hover\:border-indigo-700:hover:enabled) {
  --tw-border-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-border-opacity));
}

:is(.dark .dark\:enabled\:hover\:border-lime-700:hover:enabled) {
  --tw-border-opacity: 1;
  border-color: rgb(77 124 15 / var(--tw-border-opacity));
}

:is(.dark .dark\:enabled\:hover\:border-pink-700:hover:enabled) {
  --tw-border-opacity: 1;
  border-color: rgb(190 24 93 / var(--tw-border-opacity));
}

:is(.dark .dark\:enabled\:hover\:border-red-700:hover:enabled) {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}

:is(.dark .dark\:enabled\:hover\:border-teal-700:hover:enabled) {
  --tw-border-opacity: 1;
  border-color: rgb(15 118 110 / var(--tw-border-opacity));
}

:is(.dark .dark\:enabled\:hover\:border-yellow-700:hover:enabled) {
  --tw-border-opacity: 1;
  border-color: rgb(161 98 7 / var(--tw-border-opacity));
}

:is(.dark .dark\:enabled\:hover\:bg-cyan-700:hover:enabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}

:is(.dark .dark\:enabled\:hover\:bg-gray-700:hover:enabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

:is(.dark .dark\:enabled\:hover\:bg-green-700:hover:enabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

:is(.dark .dark\:enabled\:hover\:bg-indigo-700:hover:enabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}

:is(.dark .dark\:enabled\:hover\:bg-lime-700:hover:enabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(77 124 15 / var(--tw-bg-opacity));
}

:is(.dark .dark\:enabled\:hover\:bg-pink-700:hover:enabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(190 24 93 / var(--tw-bg-opacity));
}

:is(.dark .dark\:enabled\:hover\:bg-primary-700:hover:enabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(35 13 38 / var(--tw-bg-opacity));
}

:is(.dark .dark\:enabled\:hover\:bg-purple-700:hover:enabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

:is(.dark .dark\:enabled\:hover\:bg-red-700:hover:enabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

:is(.dark .dark\:enabled\:hover\:bg-teal-700:hover:enabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(15 118 110 / var(--tw-bg-opacity));
}

:is(.dark .dark\:enabled\:hover\:bg-yellow-700:hover:enabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(161 98 7 / var(--tw-bg-opacity));
}

:is(.dark .enabled\:dark\:hover\:bg-gray-700:hover):enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

:is(.dark .dark\:enabled\:hover\:text-white:hover:enabled), :is(.dark .enabled\:dark\:hover\:text-white:hover):enabled {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .disabled\:dark\:text-gray-500):disabled {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:bg-cyan-300) {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:bg-gray-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:bg-gray-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:bg-gray-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:bg-gray-800\/60) {
  background-color: #1f293799;
}

:is(.dark .group:hover .dark\:group-hover\:bg-green-300) {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:bg-indigo-300) {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:bg-lime-300) {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:bg-pink-300) {
  --tw-bg-opacity: 1;
  background-color: rgb(249 168 212 / var(--tw-bg-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:bg-purple-300) {
  --tw-bg-opacity: 1;
  background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:bg-red-300) {
  --tw-bg-opacity: 1;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:bg-teal-300) {
  --tw-bg-opacity: 1;
  background-color: rgb(94 234 212 / var(--tw-bg-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:bg-yellow-300) {
  --tw-bg-opacity: 1;
  background-color: rgb(253 224 71 / var(--tw-bg-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .group:focus .dark\:group-focus\:ring-gray-800\/70) {
  --tw-ring-color: #1f2937b3;
}

@media (min-width: 640px) {
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:h-10 {
    height: 2.5rem;
  }

  .sm\:h-6 {
    height: 1.5rem;
  }

  .sm\:h-7 {
    height: 1.75rem;
  }

  .sm\:w-10 {
    width: 2.5rem;
  }

  .sm\:w-6 {
    width: 1.5rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:pr-8 {
    padding-right: 2rem;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:text-xs {
    font-size: .9rem;
  }
}

@media (min-width: 768px) {
  .md\:inset-0 {
    inset: 0;
  }

  .md\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .md\:mr-6 {
    margin-right: 1.5rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:w-48 {
    width: 12rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:max-w-xl {
    max-width: 36rem;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:rounded-none {
    border-radius: 0;
  }

  .md\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .md\:border-0 {
    border-width: 0;
  }

  .md\:bg-transparent {
    background-color: #0000;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:text-sm {
    font-size: 1rem;
  }

  .md\:font-medium {
    font-weight: 500;
  }

  .md\:text-cyan-700 {
    --tw-text-opacity: 1;
    color: rgb(14 116 144 / var(--tw-text-opacity));
  }

  .md\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .md\:hover\:text-cyan-700:hover {
    --tw-text-opacity: 1;
    color: rgb(14 116 144 / var(--tw-text-opacity));
  }

  :is(.dark .md\:dark\:hover\:bg-transparent:hover) {
    background-color: #0000;
  }

  :is(.dark .md\:dark\:hover\:text-white:hover) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

@media (min-width: 1024px) {
  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:border {
    border-width: 1px;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:text-9xl {
    font-size: 8rem;
  }
}

.\[\&\>\*\]\:first\:rounded-t-lg:first-child > * {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.\[\&\>\*\]\:last\:rounded-b-lg:last-child > * {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.\[\&\>\*\]\:last\:border-b-0:last-child > * {
  border-bottom-width: 0;
}

/*# sourceMappingURL=index.29f08f18.css.map */
