@tailwind base;

@layer base {
  * {
    @apply box-border;
  }

  body {
    font-family: "filson-pro", sans-serif;
    @apply flex;
    @apply flex-col;
    @apply m-0;
    @apply w-screen;
    @apply h-screen;
    @apply overscroll-none;
    @apply bg-white;
    @apply lg:bg-beige;
    @apply text-gray-800;
  }

  #app {
    @apply flex;
    @apply flex-col;
    @apply h-full;
    @apply w-full;
    @apply items-center;
  }

  h1 {
    @apply text-4xl;
    @apply font-bold;
    @apply text-primary2;
  }

  h2 {
    @apply text-2xl;
    @apply font-bold;
    @apply text-primary2;
  }

  h3 {
    @apply text-lg;
    @apply font-bold;
    @apply text-gray-800;
  }

  video {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg); /* Firefox */
  }

  .center-container {
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply justify-center;
    @apply w-full;
    @apply h-full;
  }
  .upload-wrapper {
    @apply relative;
  }

  .upload-wrapper input[type="file"] {
    @apply absolute;
    @apply opacity-0;
    @apply left-0;
    @apply top-0;
    @apply w-full;
    @apply h-full;
    @apply cursor-pointer;
  }
}

@tailwind components;
@tailwind utilities;
